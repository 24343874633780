<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h4 class="mr-sm-4 header-tablepage">Collection DETAIL</h4>
        </div>
        <div class="title-tabs mt-3">
          <b-row>
            <b-col md="6" class="text-left">General Information</b-col>
          </b-row>
        </div>
        <b-container class="no-gutters bg-white">
          <b-row class="pt-3">
            <b-col class="col-md-6"
              ><InputText
                textFloat="Name"
                placeholder="Name"
                type="text"
                v-model="detail.name"
                :isValidate="$v.detail.name.$error"
                :v="$v.detail.name"
                isRequired
            /></b-col>
            <b-col class="col-12">
              <InputTextArea
                textFloat="Description"
                placeholder="Description"
                v-model="detail.description"
                rows="7"
                type="text"
              />
            </b-col>
          </b-row>
        </b-container>

        <div class="title-tabs">
          <b-row>
            <b-col md="6" class="text-left">Product List</b-col>
          </b-row>
        </div>

        <b-container class="no-gutters bg-white">
          <b-row class="mb-2">
            <b-col cols="2" sm="6" md="6" class="mt-2">
              <b-button @click.prevent="openModal" class="btn-filter mr-2">
                <span class="d-none d-sm-flex align-items-center">
                  <span class="mr-2">Select Items </span>
                  <font-awesome-icon icon="chevron-right" class="pointer" />
                </span>
                <font-awesome-icon icon="chevron-right" class="d-sm-none" />
              </b-button>
              <span class="text-black"
                >Selected {{ this.allItem.length }} list</span
              >
            </b-col>

            <b-col cols="10" sm="6" md="6" class="mt-2">
              <b-input-group class="mb-2">
                <b-form-input
                  type="text"
                  id="header-search-bar"
                  class="search-bar"
                  @keyup.enter="handleSearch"
                  placeholder="Search Product Name"
                  v-model="filter.search"
                >
                </b-form-input>
                <b-input-group-append is-text>
                  <b-iconstack
                    font-scale="2"
                    type="submit"
                    @click="handleSearch"
                  >
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                    ></b-icon>
                  </b-iconstack>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(detail)="{ item }">
              <div class="name-link" @click="openCollectionDetail(item)">
                Detail
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
            <!-- <template v-slot:cell(status)="{ item }">
              <div :class="item.status == '1' ? 'text-success' : 'text-error'">
                {{ item.status ? "Active" : "Inactive" }}
              </div>
            </template> -->
            <template v-slot:cell(image_url)="data">
              <div class="position-relative picture-text pic-table">
                <div
                  v-if="data.item.image_url"
                  class="pic-box"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.image_url + ')',
                  }"
                ></div>
                <div v-else>
                  <img
                    class="pic-box pt-0"
                    :src="default_image"
                    alt="default Image"
                    srcset=""
                  />
                </div>
              </div>
            </template>
            <template v-slot:cell(action)="{ item }">
              <div
                class="d-flex justify-content-center"
                @click="openModalDelete(item.id)"
              >
                <b-icon size="xl" icon="trash-fill" class="icon-size pointer" />
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :pageOptions="pageOptions"
            :filter="filter"
            :rows="rows"
            @pagination="pagination"
          />
        </b-container>
      </div>
      <FooterAction routePath="/product-collection" @submit="saveForm()" />
      <ModalSelectProduct
        ref="modalSelectProduct"
        :selectedItem="selectedItem"
        :name="detail.name"
        @success="handleSelectProduct"
      ></ModalSelectProduct>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import ModalSelectProduct from "../modal/ModalSelectProduct.vue";
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";
import InputTextArea from "@/components/inputs/InputTextArea";

export default {
  components: {
    OtherLoading,
    ModalSelectProduct,
    InputText,
    InputTextArea,
  },
  validations() {
    return {
      detail: {
        name: {
          required,
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      items: [],
      detail: {
        name: "",
        description: "",
      },
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      fields: [
        {
          key: "image_url",
          label: "Image",
          thClass: "w-2",
        },
        {
          key: "name",
          label: "Name",
          thClass: "w-2",
        },
        {
          key: "barcode",
          label: "Barcode",
          thClass: "w-2",
        },
        {
          key: "article_no",
          label: "Article No.",
          thClass: "w-2",
        },
        {
          key: "price",
          label: "Price",
          thClass: "w-2",
        },
        // {
        //   key: "status",
        //   label: "Status",
        //   thClass: "w-1",
        // },
        {
          key: "action",
          label: "",
          thClass: "w-1",
        },
      ],
      id: this.$route.params.id,
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selectedItem: [],
      deleteId: "",
      confirmMsg: "",
      allItem: [],
      rowsFormData: [],
      filteredItems: [],
    };
  },

  async created() {
    if (this.id > 0) {
      this.isLoading = true;
      await this.getDetail();
      this.isLoading = false;
    }
  },
  methods: {
    async getDetail() {
      this.isBusy = true;
      const res = await this.axios.post(
        `/ProductMenu/getbyid/collection/${this.id}`,
        this.filter
      );
      this.detail = res.data.detail;
      let list = this.detail.product_list.map((x) => ({
        name: x.name,
        id: x.id,
        barcode: x.barcode,
        article_no: x.article_no,
        price: x.price,
        image_url: x.image_url,
      }));

      this.items = list;
      this.allItem = list;
      this.rowsFormData = this.detail.total_product;
      this.rows = this.rowsFormData;
      this.filteredItems = [...this.allItem];
      this.selectedItem = list;
      this.updateDisplayedItems();
      this.isBusy = false;
    },
    async saveForm() {
      this.$v.$touch();
      this.isLoading = true;
      if (!this.$v.$error) {
        this.selectedItem = this.selectedItem.map((x) => x.id);
        if (this.id > 0) {
          let payload = {
            id: this.id,
            name: this.detail.name,
            select_product: this.selectedItem,
            description: this.detail.description,
          };

          const res = await this.axios.put(
            `/productmenu/update/collection`,
            payload
          );
          if (res.data.result === 1) {
            this.successAlert();
            this.$router.push(`/product-collection`);
          } else {
            this.errorAlert(res.data.message);
          }
        } else {
          let payload = {
            id: this.id,
            name: this.detail.name,
            select_product: this.selectedItem,
            description: this.detail.description,
          };
          const res = await this.axios.post(
            `/productmenu/create/collection`,
            payload
          );
          if (res.data.result === 1) {
            this.successAlert();
            this.$router.push(`/product-collection`);
          } else {
            this.errorAlert(res.data.message);
          }
        }
      }

      this.isLoading = false;
    },
    openModalDelete(id) {
      this.deleteId = id;
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteProduct();
        }
      });
      this.deleteId = id;
    },
    async deleteProduct() {
      this.allItem = this.allItem.filter((x) => x.id !== this.deleteId);
      this.filteredItems = this.filteredItems.filter(
        (x) => x.id !== this.deleteId
      );
      this.selectedItem = this.selectedItem.filter(
        (x) => x.id !== this.deleteId
      );
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    handleSelectProduct(items) {
      if (items) {
        this.allItem = items;
      }

      this.isBusy = true;
      const maxItems = this.filter.take;
      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = items.length;
      this.selectedItem = items;
      this.filteredItems = items;
      this.isBusy = false;
    },
    openModal() {
      this.$refs.modalSelectProduct.show(this.items);
    },
    handleSearch() {
      const searchText = this.filter.search.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter((item) =>
          item.name.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },

    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems() {
      this.isBusy = true;
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;

      this.isBusy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-gray {
  color: #939393;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
